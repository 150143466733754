import React from 'react';
import Logo1 from "../assets/logoJabar.png";
import { FaPhone, FaInstagram, FaEnvelope } from "react-icons/fa";
import { TbWorld } from "react-icons/tb";

const Contact = () => {
  return (
    <main className="main w-full h-full flex flex-col items-center py-14 lg:py-28">
      <div
        id="about"
        className="about-container w-full h-full flex items-center justify-center relative my-10 lg:my-0"
      >
        <div className="about-wrapper w-full lg:w-[70%] h-screen flex flex-col items-center justify-center">
          <div className="backline w-full p-2 lg:p-5 flex flex-col-reverse lg:flex-row items-center justify-start bg-white/30 lg:border border-gray-500 backdrop-blur-sm shadow-sm">
          <div className="wrap-info w-full h-full flex justify-center py-2 ">
            <div className="flex flex-col items-center justify-center text-gray-700 gap-2 text-xs lg:text-base px-6 lg:px-0">
            <img src={Logo1} alt="logo" className="size-80" />
           <div className="phone flex gap-2 items-center justify-center">
            <FaPhone className=" text-gray-700" />
            <p>+62822-6303-2626</p>
           </div>
           <div className="instagram flex gap-2 items-center justify-center">
            <FaInstagram className=" text-gray-700" />
            <p>@prsijabar</p>
           </div>
           <div className="email flex gap-2 items-center justify-center">
            <FaEnvelope className=" text-gray-700" />
            <p>jabarprsi@gmail.com</p>
           </div>
           <div className="world flex gap-2 items-center justify-center">
            <TbWorld className=" text-gray-700" />
            <p>www.prsijabar.com</p>
           </div>
           </div>
           </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default Contact
