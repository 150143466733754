"use client";
import { useState } from "react";
import { Visi } from "./Visi";
import { Misi } from "./Misi";
import { BlackBtn } from "../../buttons/BlackBtn";
import { motion } from "framer-motion";

export function Slides() {
  const [slide, setSlide] = useState("visi");


  const handleSlideRight = () => {
    setSlide("misi");
  };

  const handleSlideLeft = () => {
    setSlide("visi");
  };




  return (
    <div className="flex flex-col justify-center items-center w-full h-screen relative ">
         <span className="w-full h-96 bg-red-700 absolute -z-0 hidden lg:block"/>
      <div className="flex justify-center items-center gap-10 p-5 border bg-white/60 backdrop-blur-sm drop-shadow-md">
        <BlackBtn disabled={slide === "visi"} onClick={handleSlideLeft}  >Visi</BlackBtn>
        <BlackBtn disabled={slide === "misi"} onClick={handleSlideRight} >Misi</BlackBtn>
      </div>
      <div className="slide-container w-full flex flex-row overflow-x-hidden">
        <motion.div
        id="visi"
          initial={{ x: 0, opacity: 1 }}
          animate={{ x: slide === "visi" ? 0 : -100, opacity: slide === "visi" ? 1 : 0 }}
          transition={{ duration: 0.75 }}
          className={`slide w-full ${slide === "visi" ? "" : "hidden"}`}
        >
          <Visi />
        </motion.div>
        <motion.div
        id="misi"
          initial={{ x: 0, opacity: 0 }}
          animate={{ x: slide === "misi" ? 0 : 100, opacity: slide === "misi" ? 1 : 0 }}
          transition={{ duration: 0.75 }}
          className={`slide w-full ${slide === "misi" ? "" : "hidden"}`}
        >
          <Misi />
        </motion.div>
      </div>
    </div>
  );
}
