// import Image from "next/image";
import "./DotStyle.css";
import Logo1 from "../../../assets/logoJabar.png";
import { FaPhone, FaInstagram, FaEnvelope } from "react-icons/fa";
import { TbWorld } from "react-icons/tb";


export function Footer() {
  return (
    <div
      id="footer"
      className="footer-container w-full h-96 flex items-end justify-center relative"
    >
        <span className="w-full h-72 bg-red-700 absolute -z-0 "/>
        <span className="w-full h-96 bg-red-800 absolute z-[5] hidden lg:block" style={{clipPath: "polygon(0 81%, 44% 81%, 59% 0, 100% 0%, 100% 100%, 0% 100%)"}}/>
      <div className="footer-wrapper w-full lg:w-[70%] h-72 flex flex-col items-center justify-center absolute z-[10]">
        <div className="backline w-full h-full flex items-center justify-center ">
          <div className="left w-1/2 h-full relative flex justify-center ">
          <div className="wrap-logo w-full flex flex-col lg:flex-row gap-0 lg:gap-2 items-start lg:items-center justify-center lg:justify-start py-2 px-3 lg:px-0">
              
              <img src={Logo1} alt="logo" className="size-20 lg:size-36" />

              <div className="logo-text flex flex-col items-start justify-start px-3 lg:px-0">
                <p className="title font-bold text-lg lg:text-5xl text-white leading-tight">
                  PRSI
                </p>
                <p className="subtitle text-sm lg:text-xl text-gray-200 leading-tight">
                  Persatuan Robotika
                </p>
                <p className="subtitle text-sm lg:text-xl text-gray-200 leading-tight">
                  Seluruh Indonesia
                </p>
                {/* <p className="subtitle text-sm lg:text-xl text-gray-200 leading-tight block lg:hidden">
                Persatuan Robotika Seluruh Indonesia
                </p> */}
                
              </div>
            </div>
          </div>
          <div className="right w-1/2 h-full relative flex justify-center ">
          <div className="wrap-info w-full h-full flex justify-end py-2 absolute z-[100]">
            <div className="flex flex-col items-start justify-center text-white gap-2 text-xs lg:text-base px-6 lg:px-0">
           <div className="phone flex gap-2 items-center justify-center">
            <FaPhone className=" text-white" />
            <p>+62822-6303-2626</p>
           </div>
           <div className="instagram flex gap-2 items-center justify-center">
            <FaInstagram className=" text-white" />
            <p>@prsijabar</p>
           </div>
           <div className="email flex gap-2 items-center justify-center">
            <FaEnvelope className=" text-white" />
            <p>jabarprsi@gmail.com</p>
           </div>
           <div className="world flex gap-2 items-center justify-center">
            <TbWorld className=" text-white" />
            <p>www.prsijabar.com</p>
           </div>
           </div>
           </div>
          </div>
        </div>
      </div>
    </div>
  );
}
