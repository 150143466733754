"use client";
import { useState, useRef } from "react";
import { BlackBtn } from "../buttons/BlackBtn";
import "./DropMenu.css";

function BtnDrop({ label, href }) {
  return (
    <a
      href={href}
      className="hover:bg-gray-400 w-full py-2 px-2 transition-all duration-200"
    >
      {label}
    </a>
  );
}

export function DropMenu() {
  const [open, setOpen] = useState(false);
  const [animated, setAnimated] = useState();
  const menuElement = useRef(null);

  const handleMenuClick = () => {
    if (open) {
      setAnimated("menuClose");

      setTimeout(() => {
        setOpen(false);
      }, 150); // delay the state update by 300ms
    } else {
      setOpen(true);
      setAnimated("menuOpen");
    }
  };

  const options = [
    { id: 1, label: "Tentang PRSI", href: "/about" },
    { id: 2, label: "Program dan Aktivitas", href: "/activity" },
    { id: 3, label: "Mitra dan Kerjasama", href: "/partnership" },
    { id: 4, label: "Struktur Organisasi", href: "/structure" },
    { id: 5, label: "Keanggotaan", href: "/membership" },
    { id: 6, label: "Kontak", href: "/contact" },
  ];

  return (
    <div className="menu flex flex-col items-center justify-start relative">
      <BlackBtn width="w-32" textAlign={"text-start"} onClick={handleMenuClick}>
        Menu
      </BlackBtn>
      {open && (
        <ul
          ref={menuElement}
          className={`menuListed gap-3 p-2 bg-black text-white shadow-gray-400 shadow-md transition-opacity duration-300 ease-in-out ${animated}`}
          //   onAnimationEnd={handleAnimationEnd}
        >
          {options.map((items) => (
            <BtnDrop
              key={items.id}
              id={items.id}
              label={items.label}
              href={items.href}
            />
          ))}
        </ul>
      )}
    </div>
  );
}
