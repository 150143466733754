// import Image from "next/image";
import "./DotStyle.css";
import Vision from "../../../assets/Visi.jpg";
import Screens  from "../../../assets/screens.png";


export function Visi() {
  return (
    <div id="visi" className="visi-container w-full flex items-center justify-center">
      <div className="visi-wrapper w-full lg:w-[70%] h-full flex flex-col items-center justify-center">
        <div className="backline w-full h-screen flex flex-col lg:flex-row items-center justify-center">
          <div className="left w-full lg:w-1/3 h-full relative flex items-end lg:items-center justify-center">
            <div className="wrap-image absolute size-96 lg:size-[650px] gap-2 flex flex-col items-center justify-between z-20 p-4 lg:p-0">
              <div className="top flex h-3 w-full justify-between items-center">
              <span className="dots topLeft " />
              <span className="dots topRight " />
              </div>
              <div className="center border-2 flex-1 w-[95%] justify-center items-center bg-white/30">
                <div className="wrapper w-full h-full flex flex-col items-start p-2 gap-2">
                  <p className="w-24 px-3 py-1 text-black font-black rounded-[5px]">
                    Visi
                  </p>
                  <div className="image-container flex relative ">
                    <img
                      src={Vision}
                      alt="vision"
                      fill
                      className="responsive-image object-cover"
                    />
                  </div>
                </div>
              </div>
              <div className="bottom flex h-3 w-full justify-between items-center">
              <span className="dots downLeft " />
              <span className="dots downRight " />
              </div>
            </div>
          </div>
          <div className="right w-full lg:w-2/3 h-full relative flex items-start lg:items-center justify-center lg:justify-start">
            <div className="wrap-text absolute lg:h-[30%] h-[75%] w-[90%] lg:w-full  flex flex-col items-center justify-between">
             
              <div className="center centerline border-2 flex-1 w-[95%] justify-center items-center bg-white/30">
                <div className="wrapper w-full h-full flex flex-col items-start p-2 gap-2 backdrop-blur-sm">
                  
                  <div className="image-container wrap-img flex relative p-3 items-center justify-center bg-sky-200/30">
                  <img
                      src={Screens}
                      alt="vision"
                      
                      className="responsive-image object-cover opacity-10 z-0 "
                    />
                   <p className="absolute text-center lg:text-start z-10 p-5 text-sm lg:text-2xl">Menjadi organisasi terdepan dalam pengembangan dan penerapan teknologi robotika di Provinsi Jawa Barat serta membangun ekosistem yang mendukung pertumbuhan sumber daya manusia yang kompeten dalam bidang robotika di tingkat nasional dan internasional.</p>
                  </div>
                </div>
              </div>
             
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
