import { useState } from "react";
import { BlackBtn } from "../../buttons/BlackBtn";
import { motion } from "framer-motion";

export function SlideContainer({ id, title, content, direction }) {
    // Set initial and animate values based on the direction
    const initialX = direction === "next" ? 100 : -100; // Slide in from right for next, left for prev
    const animateX = 0; // Final position
  
    return (
      <motion.div
        initial={{ x: initialX, opacity: 0 }}
        animate={{ x: animateX, opacity: 1 }}
        exit={{ x: direction === "next" ? -100 : 100, opacity: 0 }} // Slide out to left for next, right for prev
        transition={{ duration: 0.75 }}
      >
        <div id={id} className={`slide-${id}`}>
          <p className="p-3 bg-gray-400/30 font-semibold">{title}</p>
          <p>{content}</p>
        </div>
      </motion.div>
    );
  }

export function SlidesText() {
  const [slide, setSlide] = useState(0);
  const [direction, setDirection] = useState(""); // Track the direction of the slide

  const slideTXT = [
    {
      id: 1,
      title: "Mendorong Pengembangan Sumber Daya Manusia di bidang Robotika",
      content:
        "PRSI Jawa Barat bertujuan untuk meningkatkan keterampilan dan pengetahuan masyarakat, terutama generasi muda , dalam bidang robotika. Melalui berbagai program pelatihan, seminar, dan kompetisi, organisasi ini berkomitmen untuk mencetak SDM yang kompeten dan siap bersaing di kancah Nasional maupun Internasional.",
    },
    {
      id: 2,
      title: "Membangun Ekosistem Robotika yang Solid di Jawa Barat",
      content:
        "PRSI Jawa Barat bertujuan untuk membangun ekosistem robotika yang solid, dimana terdapat sinergi antara akademisi, industry, pemerintah, dan komunitas. Dengan membangun jaringan yang kuat, PRSI berupaya menciptakan lingkungan yang mendukung inovasi dan riset di bidang robotika.",
    },
    {
      id: 3,
      title: "Menjadi Pusat Inovasi dan Riset Teknologi Robotika",
      content:
        "Salah satu tujuan Utama PRSI Jawa Barat adalah menjadi pusat inovasi dan riset teknologi robotika di Jawa Barat. Organisasi ini aktif mendorong dan memfasilitasi penelitian yang berfokus pada pengembangan teknologi robotika yang aplikatif dan bermanfaat bagi masyarakat luas.",
    },

    {
      id: 4,
      title: "Mengembangkan Potensi Lokal dan Meningkatkan Daya Saing",
      content:
        "PRSI Jawa Barat bertujuan untuk mengidentifikasi dan mengembangkan potensi local dalam bidang robotika, serta meningkatkan daya saing mereka di tingkat nasional maupun global. Ini dilakukan melalui pembinaan berkelanjutan, pengenalan teknologi terbaru, dan partisipasi dalam kompetisi robotika.",
    },

    {
      id: 5,
      title: "Mendukung kebijakan Pemerintah dalam Pengembangan Teknologi",
      content:
        "PRSI Jawa Barat berkomitmen untuk mendukung kebijakan pemerintah, khususnya di bidang Pendidikan dan teknologi. Organisasi ini bekerjasama dengan instansi terkait untuk mengimplementasikan program-program yang selaras dengan visi pembangunan daerah, khususnya dalam meningkatkan kualitas Pendidikan teknologi di Jawa Barat.",
    },

    {
      id: 6,
      title: "Menggalang Partisipasi Masyarakat dalam Revolusi Industri 4.0",
      content:
        "PRSI Jawa Barat bertujuan untuk mengedukasi dan mengajak masyarakat Jawa Barat berpartisipasi aktif dalam revolusi industry 4.0, dimana robotika menjadi salah satu pilar penting. Organisasi ini berperan sebagai katalisator dalam proses transformasi menuju masyarakat yang melek teknologi dan siap menghadapi tantangan masa depan.",
    },
  ];

  const increase = () => {
    if (slide < slideTXT.length - 1) {
      setDirection("next"); // Set direction for next slide
      setSlide(slide + 1);
    }
  };

  const decrease = () => {
    if (slide > 0) {
      setDirection("prev"); // Set direction for previous slide
      setSlide(slide - 1);
    }
  };

  return (
    <>
      <div className="sliders w-full flex flex-col gap-2">
        <div className="btn-slide w-full flex justify-between">
          <BlackBtn onClick={decrease} disabled={slide === 0}>Prev</BlackBtn>
          <BlackBtn onClick={increase} disabled={slide === slideTXT.length - 1}>Next</BlackBtn>
        </div>
        <div className="content h-full lg:h-64 bg-gray-300/10 p-3 overflow-hidden">
          {slideTXT.map((item, index) => (
            slide === index && (
              <SlideContainer
                key={item.id}
                id={item.id}
                title={item.title}
                content={item.content}
                direction={direction} // Pass direction to SlideContainer
              />
            )
          ))}
        </div>
      </div>
    </>
  );
}
