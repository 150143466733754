// import Image from "next/image";
import { AiFillHome } from "react-icons/ai";
import { DropMenu } from "../dropdown/DropMenu";
import { BlackBtn } from "../buttons/BlackBtn";
import Logo1 from "../../assets/logoJabar.png";
import { useNavigate } from "react-router-dom";

export function Navigation() {
  const navigate = useNavigate();

  const handleHome = () => {
    navigate("/");
    }

  return (
    <nav className="w-full h-[100px] bg-transparent backdrop-blur-sm flex items-center justify-center fixed top-0 left-0 z-[9999]">
      <div className="wrapper w-[90%] flex items-center justify-center gap-5">
        <div className="left flex gap-2 items-center">
          <a href="/">
            <img src={Logo1} alt="logo" width={80} height={80} />
          </a>
          <div className="logo-text hidden lg:flex flex-col items-start justify-start ">
            <p className="title font-bold text-2xl leading-tight">PRSI</p>
            <p className="subtitle text-sm text-gray-400 leading-tight">
              Persatuan Robotika
            </p>
            <p className="subtitle text-sm text-gray-400 leading-tight">
              Seluruh Indonesia
            </p>
          </div>
        </div>

        <span className="flex-1 h-1 bg-gray-200" />

        <div className="right flex gap-4">
          <BlackBtn onClick={handleHome}>
            <AiFillHome />
          </BlackBtn>
          <DropMenu />
        </div>
      </div>
    </nav>
  );
}
