import { useState } from "react";
import { BlackBtn } from "../../buttons/BlackBtn";
import { motion } from "framer-motion";

export function SlideContainer({ id, title, content, direction }) {
    // Set initial and animate values based on the direction
    const initialX = direction === "next" ? 100 : -100; // Slide in from right for next, left for prev
    const animateX = 0; // Final position
  
    return (
      <motion.div
        initial={{ x: initialX, opacity: 0 }}
        animate={{ x: animateX, opacity: 1 }}
        exit={{ x: direction === "next" ? -100 : 100, opacity: 0 }} // Slide out to left for next, right for prev
        transition={{ duration: 0.75 }}
      >
        <div id={id} className={`slide-${id}`}>
          <p className="p-3 bg-gray-400/30 font-semibold">{title}</p>
          <p>{content}</p>
        </div>
      </motion.div>
    );
  }

export function SlidesText() {
  const [slide, setSlide] = useState(0);
  const [direction, setDirection] = useState(""); // Track the direction of the slide

  const slideTXT = [
    {
      id: 1,
      title: "Anggota Reguler",
      content: "Individu yang memiliki minat dalam bidang robotika dan berkomitmen untuk mendukung visi dan misi PRSI."
      },
    {
      id: 2,
      title: "Anggota Profesional",
      content: "Ahli dan praktisi dalam bidang robotika yang aktif berkontribusi dalam pengembangan teknologi."
      },
    {
      id: 3,
      title: "Anggota Kehormatan",
      content: "Tokoh masyarakat atau profesional yang memberikan kontribusi signifikan dalam pengembangan robotika."
      },

    
    
  ];

  const increase = () => {
    if (slide < slideTXT.length - 1) {
      setDirection("next"); // Set direction for next slide
      setSlide(slide + 1);
    }
  };

  const decrease = () => {
    if (slide > 0) {
      setDirection("prev"); // Set direction for previous slide
      setSlide(slide - 1);
    }
  };

  return (
    <>
      <div className="sliders w-full flex flex-col gap-2">
        <div className="btn-slide w-full flex justify-between">
          <BlackBtn onClick={decrease} disabled={slide === 0}>Prev</BlackBtn>
          <BlackBtn onClick={increase} disabled={slide === slideTXT.length - 1}>Next</BlackBtn>
        </div>
        <div className="content h-full lg:h-64 bg-gray-300/10 p-3 overflow-hidden">
          {slideTXT.map((item, index) => (
            slide === index && (
              <SlideContainer
                key={item.id}
                id={item.id}
                title={item.title}
                content={item.content}
                direction={direction} // Pass direction to SlideContainer
              />
            )
          ))}
        </div>
        <div className="terms">
          Syarat Keanggotaan :
          <ul className="leading-1 list-disc">
            <li>Anggota harus memiliki minat dan dedikasi yang tinggi dalam bidang robotika.</li>
            <li>Mengisi Formulir Pendaftaran</li>
            <li>Aktif berpartisipasi dalam kegiatan organisasi.</li>

          </ul>
        </div>
      </div>
    </>
  );
}
