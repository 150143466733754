import robotArms from "./assets/RobotArms.png";
import { Navigation } from "./components/navigation/Navbar";
import { Routing } from "./Routing";
import { Footer } from "./components/pageComponents/Home/Footer";
function App() {
  return (
    <>
      <div className="background fixed w-full h-full top-0 left-0 z-[-1] flex flex-col items-start justify-end">
        <img
          src={robotArms}
          alt="logo"
          width={971}
          height={912}
          className="w-full lg:w-[75%]  lg:h-auto translate-x-[5%] translate-y-[10%] lg:translate-y-[30%] absolute opacity-30"
        />
        <img
          src={robotArms}
          alt="logo"
          width={657}
          height={615}
          className="w-full lg:w-[50%] lg:h-auto scale-x-[-1] translate-x-[98%] translate-y-[30%] absolute opacity-30"
        />
        {/* <Image src={robotArms} alt="logo" width={971} height={912} className="translate-x-[5%] lg:translate-y-[30%] absolute" />
          <Image src={robotArms} alt="logo" width={657} height={615} className="scale-x-[-1] translate-x-[90%] translate-y-[30%] absolute" /> */}
      </div>
      <Navigation />
      <Routing />
      <Footer />
    </>
  );
}

export default App;
