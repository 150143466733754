// import Image from "next/image";
import Logo1 from "../../../assets/logoJabar.png";
import HeroImg from "../../../assets/HeroImg.png";
import HeroImg2 from "../../../assets/HeroImg2.jpg";

export function Hero() {
  return (
    <div className="hero-container min-h-screen h-screen w-full flex flex-col ">
      <div className="hero-wrapper w-full h-full flex flex-col items-center justify-center">
        <div className="backline w-full h-80 bg-red-700 flex flex-col lg:flex-row items-center justify-center">
          <div className="left w-full lg:w-1/2 h-full">
            <div className="wrap-logo w-full h-full flex flex-col lg:flex-row gap-0 lg:gap-2 items-center justify-center py-2 lg:py-0">
              <img src={Logo1} alt="logo" width={156} height={156} />

              <div className="logo-text flex flex-col items-center lg:items-start justify-center lg:justify-start ">
                <p className="title font-bold text-5xl text-white leading-tight">
                  PRSI
                </p>
                <p className="subtitle text-xl text-gray-200 leading-tight hidden lg:block">
                  Persatuan Robotika
                </p>
                <p className="subtitle text-xl text-gray-200 leading-tight hidden lg:block">
                  Seluruh Indonesia
                </p>
                <p className="subtitle text-sm lg:text-xl text-gray-200 leading-tight block lg:hidden">
                Persatuan Robotika Seluruh Indonesia
                </p>
              </div>
            </div>
          </div>
          <div className="right w-full lg:w-1/2 h-full ">
            <div className="wrap-img w-full h-full relative hidden lg:flex">
              <img
                src={HeroImg}
                alt="heroimg"
                width={485}
                className="h-auto absolute top-0 z-0 -translate-y-32"
              />
              
            </div>
            <div className="wrap-img w-full h-full flex justify-center items-center relative lg:hidden">
              <img
                src={HeroImg2}
                alt="heroimg"
                width={485}
                height="auto"
                className="absolute top-0 z-0"
              />
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
