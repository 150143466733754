import LandingPage from "./pages/LandingPage";
import AboutPage from "./pages/AboutPage";
import Contact from "./pages/Contact";
import Activity from "./pages/Activity"
import Membership from "./pages/Membership"
import Structure from "./pages/Structure"
import Partnership from "./pages/Partnership"
import { Route, Routes } from 'react-router-dom';

export function Routing () {
    return <>
    <Routes>
        <Route path="/" element={<LandingPage/>} />
        <Route path="/about" element={<AboutPage/>} />
        <Route path="/activity" element={<Activity/>} />
        <Route path="/membership" element={<Membership/>} />
        <Route path="/partnership" element={<Partnership/>} />
        <Route path="/structure" element={<Structure/>} />
        <Route path="/contact" element={<Contact/>} />
      </Routes>
    </>
}