// import Image from "next/image";
import "./DotStyle.css";
import About from "../../../assets/children.jpg";



export function Programs() {
  return (
    <div id="about" className="about-container w-full h-screen flex items-center justify-center relative my-10 lg:my-0">
         <span className="w-full h-96 bg-red-600 absolute -z-0 hidden lg:block"/>
      <div className="about-wrapper w-full lg:w-[70%] h-full flex flex-col items-center justify-center">
        <div className="backline w-full p-5 flex flex-col-reverse lg:flex-row items-center justify-start bg-white/30 lg:border border-gray-500 backdrop-blur-sm shadow-sm">
          <div className="left w-full lg:w-1/3 h-96 lg:h-[700px] relative flex items-end lg:items-center justify-center p-5">
            <div className="wrap-image size-96 lg:size-[650px] gap-2 flex flex-col items-center justify-between z-20 p-4 lg:p-0">
              <div className="top flex h-3 w-full justify-between items-center">
              <span className="dots topLeft " />
              <span className="dots topRight " />
              </div>
              <div className="center border-2 flex-1 w-[95%] justify-center items-center bg-white/30">
                <div className="wrapper w-full h-full flex flex-col items-start p-2 gap-2">
                 
                  <div className="wrap-img w-full h-full flex relative ">
                    <img
                      src={About}
                      alt="about"
                      fill
                      className="object-cover"
                    />
                  </div>
                </div>
              </div>
              <div className="bottom flex h-3 w-full justify-between items-center">
              <span className="dots downLeft " />
              <span className="dots downRight " />
              </div>
            </div>
          </div>
          <div className="right w-full lg:w-2/3 h-full flex items-start lg:items-center justify-center lg:justify-start bg-white/40 " >
            <div className="wrap-text h-full w-[90%] lg:w-full flex flex-col items-center justify-start p-3">
            <div className="wrap-image w-full gap-2 flex flex-col items-center justify-between z-20 p-2 border-2 bg-gray-400/30">
              <div className="top flex h-3 w-full justify-between items-center">
              <span className="dots topLeft " />
              <span className="dots topRight " />
              </div>
              <div className="center flex w-[95%] justify-center items-center text-xl font-bold">
                <p>PRSI JABAR</p>
              </div>
              <div className="bottom flex h-3 w-full justify-between items-center">
              <span className="dots downLeft " />
              <span className="dots downRight " />
              </div>
            </div>
            <div className="text-justify text-sm lg:text-2xl  my-0 lg:my-10 flex flex-col gap-5">
             <p>Persatuan Robotika Seluruh Indonesia (PRSI) DPD Provinsi Jawa Barat adalah organisasi yang dibentuk dengan tujuan untuk memajukan dan mengembangkan teknologi robotika di Jawa Barat. </p>
             <p>
             Dengan perkembangan pesat dalam bidang teknologi, khususnya robotika, PRSI Jawa Barat hadir sebagai wadah bagi para penggiat, pelajar, akademisi, dan profesional untuk berkolaborasi, berbagi pengetahuan, dan berinovasi.</p>
             <p>Dengan berbagai tujuan yang telah dirumuskan, PRSI DPD Provinsi Jawa Barat bertekad untuk menjadi motor penggerak utama dalam perkembangan robotika di daerah ini. </p>
             <p>Kami percaya bahwa melalui kerja sama yang kuat dan komitmen yang tinggi, Jawa Barat akan menjadi salah satu pusat unggulan dalam bidang robotika di Indonesia.</p>
             </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
