import React from 'react';
import About from "../assets/children.jpg";

const struct = [
  { id: 1, title: "Dewan Pembina", name: "Dani Noor Ramdhanm S.Sos." },
  { id: 2, title: "Ketua", name: "Andra Fauzia Erlangga, S.I.P."},
  { id: 3, title: "Wakil Ketua", name: "Muhammad Wido Fahlevi,S.T."},
  { id: 4, title: "Sekretaris", name: "Derry Susanto,S.H."},
  { id: 5, title: "Wakil Sektretaris", name: "Haykal Muhammad, Amd."},
  { id: 6, title: "Bendahara", name: "Yani Suryani"},
  {id:7, title: "Hukum & Advokat", name: "Chindy Citra Agnesyah, S.H, M.Kn."},
  {id:8, title: "Humas", name: "Elfan Frianto, S.T."},
  {id:9, title: "Ketua Bidang Kelembagaan", name: "Yosi Wihara, S.E."},
  {id:10, title: "Ketua BIdang Pendidikan Robotika", name: "Ajang Rahmat, S.Kom."},
  {id:11, title: "Ketua Bidang Bisnis & Pengembangan", name: "Riki Noviyana, S.E."},
  {id:12, title: "Ketua Bidang Logistik", name: "Mulky Mulk Syawal Setia, S.Pt."}
]


const Structure = () => {
  return (
    <main className="main w-full h-full flex flex-col items-center py-14 lg:py-28">
    <div
      id="about"
      className="about-container w-full h-full flex items-center justify-center relative my-10 lg:my-0"
    >
      <div className="about-wrapper w-full lg:w-[70%] h-full flex flex-col items-center justify-center">
        <div className="backline w-full p-2 lg:p-5 flex flex-col-reverse lg:flex-row items-center justify-start bg-white/30 lg:border border-gray-500 backdrop-blur-sm shadow-sm">
          <div className="left w-full lg:w-1/3 h-96 lg:h-[700px] relative flex items-end lg:items-center justify-center p-2 lg:p-5 ">
            <div className="wrap-image size-96 lg:size-[650px] gap-2 flex flex-col items-center justify-between z-20 p-4 lg:p-0">
              <div className="top flex h-3 w-full justify-between items-center">
                <span className="dots topLeft " />
                <span className="dots topRight " />
              </div>
              <div className="center border-2 flex-1 w-[95%] justify-center items-center bg-white/30">
                <div className="wrapper w-full h-full flex flex-col items-start p-2 gap-2">
                  <div className="wrap-img w-full h-full flex relative ">
                    <img
                      src={About}
                      alt="about"
                      fill
                      className="object-cover"
                    />
                  </div>
                </div>
              </div>
              <div className="bottom flex h-3 w-full justify-between items-center">
                <span className="dots downLeft " />
                <span className="dots downRight " />
              </div>
            </div>
          </div>
          <div className="right w-full lg:w-2/3 h-full flex items-start lg:items-center justify-center lg:justify-start bg-white/40 ">
            <div className="wrap-text h-full w-full lg:w-full flex flex-col items-center justify-start p-3">
              <div className="wrap-image w-full gap-2 flex flex-col items-center justify-between z-20 p-2 border-2 bg-gray-400/30">
                <div className="top flex h-3 w-full justify-between items-center">
                  <span className="dots topLeft " />
                  <span className="dots topRight " />
                </div>
                <div className="center flex w-[95%] justify-center items-center text-xl font-bold">
                  <p>Struktur Kepengurusan PRSI Jabar</p>
                </div>
                <div className="bottom flex h-3 w-full justify-between items-center">
                  <span className="dots downLeft " />
                  <span className="dots downRight " />
                </div>
              </div>
              <div className="Structure-container w-full text-justify text-xs lg:text-sm my-0 lg:my-10 flex flex-col gap-3">
                {struct.map((item) => (
                  <div key={item.id} className="Structure-list w-full flex flex-col lg:flex-row gap-1 lg:gap-0 justify-between items-start lg:items-center">
                <p className='font-semibold'>{item.title}</p>
                <p>{item.name}</p>
                </div>))}
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  )
}

export default Structure
