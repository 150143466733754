

export function BlackBtn({
  children,
  onClick,
  width,
  textAlign,
  textStyle,
  disabled,
}) {

  const disables = disabled ? disabled.toString() : "false";
  
  return (
    <button
      onClick={onClick}
      disable={disables}
      className={`home ${width} ${textAlign} ${textStyle} px-5 py-2 bg-black text-white shadow-gray-400 shadow-md hover:bg-gray-700 focus:bg-gray-900 active:bg-gray-500`}
    >
      {children}
    </button>
  );
}
