import React from 'react'
import {Hero} from '../components/pageComponents/Home/Hero'
import {Slides} from '../components/pageComponents/Home/Slides'
import {Programs} from '../components/pageComponents/Home/Programs'


const LandingPage = () => {
  return (
    <main className="main w-full h-full flex flex-col items-center">
    <Hero />
    <Slides />
    <Programs />
    
  </main>
  )
}

export default LandingPage
