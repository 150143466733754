// import Image from "next/image";
import "./DotStyle.css";
import Mission from "../../../assets/Misi.jpg";
import Screens from "../../../assets/screens.png";

export function Misi() {
  return (
    <div
      id="misi"
      className="misi-container w-full h-full flex items-center justify-center"
    >
      <div className="misi-wrapper w-full lg:w-[70%]  h-full flex flex-col items-center justify-center">
        <div className="backline w-full h-screen flex flex-col lg:flex-row items-center justify-center">
          <div className="left w-full lg:w-1/3 h-full relative flex items-end lg:items-center justify-center">
            <div className="wrap-image absolute size-96 lg:size-[650px] gap-2 flex flex-col items-center justify-between z-20 p-4 lg:p-0">
              <div className="top flex h-3 w-full justify-between items-center">
                <span className="dots topLeft " />
                <span className="dots topRight " />
              </div>
              <div className="center border-2 flex-1 w-[95%] justify-center items-center bg-white/30">
                <div className="wrapper w-full h-full flex flex-col items-start p-2 gap-2">
                  <p className="w-24 px-3 py-1 text-black font-black rounded-[5px]">
                    Misi
                  </p>
                  <div className="image-container wrap-img flex relative ">
                    <img
                      src={Mission}
                      alt="mission"
                      fill
                      className="responsive-image object-cover"
                    />
                  </div>
                </div>
              </div>
              <div className="bottom flex h-3 w-full justify-between items-center">
                <span className="dots downLeft " />
                <span className="dots downRight " />
              </div>
            </div>
          </div>
          <div className="right w-full lg:w-2/3 h-full relative flex items-start lg:items-center justify-center lg:justify-start">
            <div className="wrap-text absolute lg:h-[30%] h-[75%] w-[90%] lg:w-full  flex flex-col items-center justify-between">
              <div className="center centerline border-2 flex-1 w-[95%] justify-center items-center bg-white/30">
                <div className="wrapper w-full h-full flex flex-col items-start p-2 gap-2 backdrop-blur-sm">
                  <div className="image-container wrap-img w-full h-full flex relative p-3 items-center justify-center bg-sky-200/30">
                    <img
                      src={Screens}
                      alt="logo"
                      fill
                      className="responsive-image object-cover opacity-10 z-0 "
                    />
                    <ul className="absolute z-10 p-5 text-xs lg:text-xl list-disc list-inside text-justify">
                      <li className="py-2">
                        Meningkatkan minat dan keterampilan masyarakat Jawa
                        Barat dalam bidang robotika melalui pendidikan,
                        pelatihan dan kompetisi.
                      </li>
                      <li className="py-2">
                        Memfasilitasi kolaborasi antara akademisi, industri, dan
                        pemerintah dalam pengembangan teknologi robotika.
                      </li>
                      <li className="py-2">
                        Mengembangkan dan mendukung riset serta inovasi di
                        bidang robotika.
                      </li>
                      <li className="py-2">
                        Menyediakan platform bagi anggota untuk berbagi
                        pengetahuan dan pengalaman dalam teknologi robotika.
                      </li>
                      <li className="py-2">
                        Mendorong partisipasi aktif masyarakat dalam kompetisi
                        robotika nasional dan internasional.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
